<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="设备ID" prop="deviceId">
        <a-input v-model="form.deviceId" placeholder="请输入设备ID" />
      </a-form-model-item>
      <a-form-model-item label="设备厂家" prop="manufactor">
        <a-input v-model="form.manufactor" placeholder="请输入设备厂家" />
      </a-form-model-item>
      <a-form-model-item v-if="scaleId" label="驿站名称" prop="stationName">
        <!-- {{ form.stationName }} -->
        <a-select
          show-search
          allowClear
          style="width: 100%"
          placeholder="请输入驿站模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleStationSearch"
          @change="handleStationChange"
          :loading="hsloading"
          v-model="postStationId"
        >
          <a-select-option v-for="(item, index) in stationList" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="锁定标识" prop="lockFlag">
        {{ optionsFormat(lockFlagOptions, form.lockFlag) }}
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="saveLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  getIntelligentScale,
  addIntelligentScale,
  updateIntelligentScale,
  bindStation
} from '@/api/recyclable/intelligentScale'
import debounce from 'lodash/debounce'
import { listAll } from '@/api/recyclable/postStationInfo'

export default {
  name: 'CreateForm',
  props: {
    lockFlagOptions: {
      type: Array,
      default: () => []
    },
    statusOptions: {
      type: Array,
      default: () => []
    },
    optionsFormat: {
      type: Function,
      default: () => () => {}
    },
    stationList: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    this.handleStationSearch = debounce(this.handleStationSearch, 500)
    return {
      loading: false,
      saveLoading: false,
      formTitle: '',
      postStationId: undefined,
      hsloading: false,
      // 表单参数
      form: {
        deviceId: null,
        manufactor: null,
        tenantCode: null,
        postStationId: null,
        status: 0,
        lockFlag: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        deviceId: [{ required: true, message: '设备ID不能为空', trigger: 'blur' }]
      },
      stationInfo: {
        id: '',
        name: ''
      },
      scaleId: ''
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    listPostStationInfo(name, arrName, loadingName) {
      listAll({
        stationName: name
      }).then(res => {
        this[loadingName] = false
        this[arrName] = res.data
      })
    },
    handleStationSearch(value) {
      console.log('handleStationSearch', value)
      this.hsloading = true
      this.listPostStationInfo(value, 'stationList', 'hsloading')
    },
    handleStationChange(value) {
      console.log('handleStationChange', value)
      if (!value) {
        this.stationInfo = {
          id: '',
          name: ''
        }
        return
      }
      this.stationInfo.id = value
      const tmpArray = this.stationList.filter(p => p.id === value)
      if (tmpArray.length > 0) {
        this.stationInfo.name = tmpArray[0].name
      }
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        deviceId: null,
        manufactor: null,
        tenantCode: null,
        postStationId: null,
        status: 0,
        lockFlag: null
      }
      this.postStationId = undefined
      this.scaleId = ''
      this.stationInfo = {
        id: '',
        name: ''
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      this.scaleId = id
      getIntelligentScale(id).then(response => {
        this.form = response.data
        for (const key in this.form) {
          if (typeof this.form[key] === 'number') {
            this.form[key] = this.form[key] + ''
          }
        }
        this.postStationId = this.form.stationName
        this.open = true
        this.formTitle = '修改'
      })
    },
    bindStation() {
      return new Promise((resolve, reject) => {
        // 绑定驿站
        bindStation({
          id: this.scaleId,
          stationId: this.stationInfo.id,
          stationName: this.stationInfo.name
        })
          .then(res => {
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateIntelligentScale(this.form)
              .then(async response => {
                if (this.stationInfo.id) {
                  await this.bindStation()
                }
                this.saveLoading = false
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          } else {
            addIntelligentScale(this.form)
              .then(async response => {
                if (this.stationInfo.id) {
                  await this.bindStation()
                }
                this.saveLoading = false
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
